<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="row" v-else>
      <div :class="'col-lg-12'">
        <div class="card">
          <div class="card-body">
            <form class="form-horizontal">
              <!-- <form action="#" @submit.prevent="showModal({type:'submit', data:payload.addedUser})"> -->
              <div class="form-body">
                <h3 class="box-title">Disposisi</h3>
                <hr class="m-t-0" />
                <template>
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Nomor Surat
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        <b>{{ this.surat.nomorsurat }}</b>
                      </label>
                    </div>
                  </div>
                  <hr class="m-t-10" />

                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Isi Ringkas
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        <b v-html="this.surat.description"></b>
                      </label>
                    </div>
                  </div>
                  <hr class="m-t-10" />
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Diteruskan Kepada
                        <small style="color: red">*</small>
                      </label>
                    </div>
                  </div>

                  <div class="row" v-if="userDisposisi.length > 0">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label
                          :class="[
                            'control-label',
                            { 'text-right': !isMobile },
                            'col-md-1',
                          ]"
                        ></label>
                        <div class="col-md-9 col-sm-9">
                          <div class="checkbox">
                            <input
                              @click="checkAll()"
                              v-model="isCheckAll"
                              id="index"
                              type="checkbox"
                            />
                            <label for="index">Centang Semua</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="isMenlu" class="row">
                    <div
                      :class="{
                        'offset-md-1 col-md-10':
                          value.head == undefined ||
                          (value.head != undefined && value.head == true),
                        'offset-md-2 col-md-4':
                          value.head != undefined && value.head == false,
                      }"
                      v-for="(value, index) in userDisposisi"
                      :key="index"
                    >
                      <br
                        v-if="
                          value.head == undefined ||
                          (value.head != undefined && value.head == true)
                        "
                      />
                      <div class="row">
                        <div :class="'col-md-11 col-sm-12'">
                          <div>
                            <!-- <input
                              :id="'checkbox-a-' + index"
                              :value="value"
                              v-model="addedUserFirst"
                              v-if="addedUser[index]"
                              @change="updateValue($event, index)"
                              type="checkbox"
                              class="m-r-5"
                            /> -->
                            <input
                              class="m-r-5"
                              :id="'checkbox-' + index"
                              :value="value"
                              v-model="addedUser[index]"
                              @change="updateCheckall($event, index)"
                              type="checkbox"
                            />
                            <label :for="'checkbox-' + index">{{
                              value.text
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div
                      :class="{
                        'col-md-6':
                          value.head == undefined ||
                          (value.head != undefined && value.head == true),
                        'col-md-12':
                          value.head != undefined && value.head == false,
                      }"
                      v-for="(value, index) in userDisposisi"
                      :key="index"
                    >
                      <div class="row">
                        <label
                          :class="[
                            'control-label',
                            { 'text-right': !isMobile },
                            'col-md-1',
                          ]"
                        ></label>
                        <div :class="'col-md-11 col-sm-12'">
                          <div>
                            <!-- <input
                              :id="'checkbox-a-' + index"
                              :value="value"
                              v-model="addedUserFirst"
                              v-if="addedUser[index]"
                              @change="updateValue($event, index)"
                              type="checkbox"
                              class="m-r-5"
                            /> -->
                            <input
                              class="m-r-5"
                              :id="'checkbox-' + index"
                              :value="value"
                              v-model="addedUser[index]"
                              @change="updateCheckall($event, index)"
                              type="checkbox"
                            />
                            <label :for="'checkbox-' + index">{{
                              value.text
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="m-t-10" />
                  <!-- <div class="row" v-if="this.surat.login == 'Eselon IV'"> -->
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Personal
                      </label>
                      <div
                        :class="[{ invalid: isInvalidPersonal }, 'col-md-12']"
                      >
                        <Multiselect
                          v-model="payload.personal"
                          label="text"
                          track-by="value"
                          placeholder="Pilih Personal"
                          open-direction="bottom"
                          :options="dropdownPersonal.personalList"
                          :multiple="true"
                          :searchable="true"
                          :value="dropdownPersonal.value"
                          :loading="dropdownPersonal.isLoadingPersonal"
                          :internal-search="false"
                          :clear-on-select="true"
                          :close-on-select="true"
                          :options-limit="300"
                          :max-height="600"
                          :show-no-results="true"
                          :hide-selected="true"
                          @input="onChangePersonal"
                          @search-change="personal"
                        >
                          <span slot="noResult">Oops! No Data found.</span>
                        </Multiselect>
                        <label
                          class="typo__label form__label"
                          v-show="isInvalidPersonal"
                          >Must have at least one value</label
                        >
                      </div>
                    </div>
                  </div>
                  <hr class="m-t-10" />
                  <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Isi Disposisi
                        <small style="color: red">*</small>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      :class="{
                        'offset-md-1 col-md-10':
                          value.head == undefined ||
                          (value.head != undefined && value.head == true),
                        'offset-md-2 col-md-4':
                          value.head != undefined && value.head == false,
                      }"
                      v-for="(row, index) in disposisiCommand"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <label :class="['control-label', 'col-md-12']">
                            <div v-for="(r, i) in row.data" :key="i">
                              <div class="checkbox">
                                <input
                                  :value="r.value"
                                  v-model="addedCommand"
                                  :id="r.value"
                                  type="checkbox"
                                />
                                <label :for="r.value">{{ r.text }}</label>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <hr />
                <template>
                  <div class="row">
                    <div
                      :class="[
                        'col-md-12 m-t-10 m-b-10',
                        { 'has-danger': errors.has('tanggal_penyesuaian') },
                      ]"
                    >
                      <label>Tanggal Penyelesaian</label>
                      <small style="color: red">*</small>
                      <div>
                        <Input
                          v-model="payload.tanggal_penyelesaian"
                          name="tanggal_penyelesaian"
                          placeholder="YYYY-MM-DD"
                          type="date"
                          v-validate="'required'"
                        />
                        <small
                          class="form-control-feedback"
                          v-show="errors.has('tanggal_penyelesaian')"
                          >this field is required</small
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <hr />
                <template>
                  <div class="row">
                    <div class="col-md-12 m-t-10 m-b-10">
                      <label>Konfirmasi Kehadiran</label>
                      <div>
                        <select
                          class="form-control"
                          v-model="payload.hadir"
                          name="hadir"
                          id="hadir"
                        >
                          <option disabled value="">Pilih Kehadiran</option>
                          <option value="1">Hadir</option>
                          <option value="0">Tidak Hadir</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </template>
                <hr />
                <template>
                  <div class="row m-t-10">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']"
                        >Catatan Disposisi</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 m-t-10 m-b-10">
                      <wysiwyg v-model="myHTML" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label :class="['control-label', 'col-md-12']"
                          >File Lampiran</label
                        >
                        <h6>
                          File Lampiran dalam format PDF dengan ukuran maksimal
                          20MB
                        </h6>
                        <div class="col-md-12 col-sm-12">
                          <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                          <table v-else class="table table-stripped">
                            <tr>
                              <th>{{ $t("file") }}</th>
                              <th>{{ $t("action") }}</th>
                            </tr>
                            <tr
                              v-for="file in files"
                              v-bind:key="file.filename"
                            >
                              <td>
                                {{
                                  file.filepath !== undefined
                                    ? file.filename
                                    : file.name
                                }}
                              </td>
                              <td>
                                <span v-if="file.filepath !== undefined">
                                  <a
                                    class="btn btn-success text-white"
                                    @click="download(file.uuid, file.filename)"
                                    ><i class="fa fa-download text-white"></i>
                                  </a>
                                  <a
                                    class="btn btn-info text-white"
                                    @click="prev(file.uuid)"
                                    ><i class="fa fa-eye text-white"></i>
                                  </a>
                                </span>
                                <i
                                  ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                                >
                                <a
                                  class="btn btn-danger text-white"
                                  @click="removeAttachment(file.uuid)"
                                >
                                  <i class="fa fa-trash-o text-white"></i>
                                </a>
                              </td>
                            </tr>
                          </table>
                          <Gallery :uploader="uploader" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <hr />
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <!-- <button
                          :disabled="
                            (addedUser.length === 0 &&
                              payload.personal.length === 0) ||
                            disposisiCommand.length === 0
                          "
                          type="button"
                          @click.prevent="save()"
                          class="btn btn-info"
                          title="Simpan"
                        >
                          <i class="fa fa-check"></i>
                          Simpan</button
                        >&nbsp; -->
                        <button
                          :disabled="
                            (addedUser.length === 0 &&
                              payload.personal.length === 0) ||
                            disposisiCommand.length === 0
                          "
                          type="button"
                          @click.prevent="send()"
                          class="btn btn-success"
                          title="Kirim"
                        >
                          <i class="mdi mdi-send"></i>
                          Kirim</button
                        >&nbsp;
                        <button
                          type="button"
                          @click="goBack()"
                          class="btn btn-inverse"
                          title="Cancel"
                        >
                          Cancel
                        </button>
                        <!-- <button type="button" @click="showModal({type:'cancel'})" class="btn btn-inverse">Cancel</button> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
            <!-- <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" @close="onClose" /> -->
          </div>
        </div>

        <hr />
        <div class="card">
          <h4>Petunjuk</h4>
          <table>
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <span class="btn btn-success"
                  ><i class="mdi mdi-send"></i> Kirim</span
                >
              </td>
              <td>Untuk kirim disposisi surat</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";
import { RotateSquare5 } from "vue-loading-spinner";
import moment from "moment";

export default {
  components: {
    RotateSquare5,
    Upload,
    Input,
    Multiselect,
  },
  computed: {
    state() {
      return this.$store.state.documentIn;
    },
    isMenlu() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.unit_id == 360 || user.unit_id == 361;
    },
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    },
  },
  data() {
    const uploader = this.fineUploader(this, "disposition_in");
    return {
      uploader,
      files: [],
      myHTML: "",
      value: [],
      user: [],
      surat: [],
      payload: {
        agenda_number: "",
        personal: [],
        photos: "",
        tanggal_penyelesaian: "",
        hadir: "",
      },
      addedUser: [],
      addedUserFirst: [],
      userDisposisi: [],
      commandUser: [],
      disposisiCommand: [],
      dropdownPersonal: {
        isTouched: false,
        personalList: [],
        value: [],
        isLoadingPersonal: false,
      },
      addedCommand: [],
      isCheckAll: false,
    };
  },
  async mounted() {
    var query = "";
    this.getSurat(query);
    this.getIntruksi(query);
    this.getUserDispo(query);
    this.personal(query);
    this.initialize();
  },

  methods: {
    // onClose(){
    //   this.isVisible = false;
    // },
    // showModal(option){
    //   this.isVisible = true;
    //   if(option.type == 'submit'){
    //     if(!empty(option.data)){
    //       this.modalTitle = "Disposisi Surat?";
    //       this.modalContent = "<p>Apakah anda yakin ingin mendisposisi surat?</p>";
    //       this.isConfirmation = true;
    //       this.ActionChangeable = this.submit;
    //     }
    //   }else if(option.type == 'cancel'){
    //     this.modalTitle = "Kembali?";
    //     this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
    //     this.isConfirmation = true;
    //     this.ActionChangeable = this.goBack;
    //   }
    // },
    async initialize() {
      try {
        const state = {
          loaded: false,
        };
        this.$store.commit("documentIn/STATE", state);
        await this.$store.dispatch(
          "documentIn/getDocumentInById",
          this.$route.params
        );
        this.$store.dispatch("documentIn/onDisposisi", this.$route.params);
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.addedUser = [];
      this.addedUserFirst = [];
      if (this.isCheckAll) {
        for (var key in this.userDisposisi) {
          this.userDisposisi[key].defaultValue = 1;
          this.addedUser.push(this.userDisposisi[key]);
        }
      }
    },
    findValue(data) {
      return data.value === 1;
    },
    changeDefaultValueAsOne(data) {
      return (data.defaultValue = 1);
    },
    changeDefaultValueAsTwo(data) {
      return (data.defaultValue = 2);
    },
    updateValue(e, index) {
      if (e.target.checked) {
        this.addedUser[index].defaultValue = 2;
      } else {
        this.addedUser[index].defaultValue = 1;
      }
    },
    updateCheckall(e, index) {
      let data = e.target._value;

      if (this.addedUser[index] && data) {
        this.addedUser[index] = data;
      }
      let tempData = [];
      this.addedUser.forEach((value) => {
        if (value != null || value != false || value != undefined) {
          tempData.push(value);
        }
      });
      if (tempData.length == this.userDisposisi.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    goBack() {
      this.$store.dispatch("disposisi/onCancel");
    },
    send() {
      if (this.payload.photos) {
        this.myFile = this.payload.photos.split(",");
      }
      const addedUser = [];
      this.addedUser.forEach((value) => {
        if (value != null && value != false && value != undefined) {
          addedUser.push(value);
        }
      });
      const payload = {
        agenda_number: this.surat.agenda_number,
        user: addedUser,
        disposisi: this.addedCommand,
        personal: this.payload.personal,
        tanggal_penyelesaian: this.payload.tanggal_penyelesaian,
        hadir: this.payload.hadir,
        catatan: this.myHTML,
        files: this.files,
        document_in: this.$route.params.id,
        is_send: true,
      };

      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$store
            .dispatch("documentIn/sendDisposisi", data)
            .then((result) => {
              this.showModal({
                type: "information",
                agenda_number: this.surat.agenda_number,
              });
              this.isVisible = true;
            })
            .catch((err) => {
              this.isVisible = false;
            });
        } else {
          const message = {
            title: this.$t("error"),
            text: this.$t("fill_form_first"),
            type: "error",
          };
          this.$store.commit("addNotification", message);
          window.scrollTo(0, 0);
          this.isVisible = false;
        }
      });
    },
    save() {
      if (this.payload.photos) {
        this.myFile = this.payload.photos.split(",");
      }
      const addedUser = [];
      this.addedUser.forEach((value) => {
        if (value != null && value != false && value != undefined) {
          addedUser.push(value);
        }
      });
      const payload = {
        agenda_number: this.surat.agenda_number,
        user: addedUser,
        disposisi: this.addedCommand,
        personal: this.payload.personal,
        tanggal_penyelesaian: this.payload.tanggal_penyelesaian,
        hadir: this.payload.hadir,
        catatan: this.myHTML,
        document_in: this.$route.params.id,
        files: this.files,
        is_send: false,
      };

      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$store
            .dispatch("documentIn/saveDisposisi", data)
            .then((result) => {
              this.showModal({
                type: "information",
                agenda_number: this.surat.agenda_number,
              });
              this.isVisible = true;
            })
            .catch((err) => {
              this.isVisible = false;
            });
        } else {
          const message = {
            title: this.$t("error"),
            text: this.$t("fill_form_first"),
            type: "error",
          };
          this.$store.commit("addNotification", message);
          window.scrolTo(0, 0);
          this.isVisible = false;
        }
      });
    },

    personal(query) {
      this.dropdownPersonal.isLoadingPersonal = true;
      axios
        .get(`document_in/list?s=${query}`)
        .then((res) => {
          this.dropdownPersonal.personalList = res.data.items;
          this.dropdownPersonal.isLoadingPersonal = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    getSurat(query) {
      axios.get(`/document_in/${this.$route.params.id}`).then((response) => {
        this.surat = response.data.data;
      });
    },
    getUserDispo(query) {
      var user = JSON.parse(localStorage.getItem("user"));
      axios
        .post(
          `disposition_in/disposition?s=${query}&structural=${user.structural_position_id}&unit_id=${user.unit_id}`
        )
        .then((res) => {
          res.data.items.forEach((row) => {
            if (user.unit_id != row.value) {
              this.userDisposisi.push({
                value: row.value,
                text: row.text,
                head: row.head,
                defaultValue: 1,
              });
            }
          });
        });
    },
    getIntruksi() {
      axios.get(`document_statuses/list`).then((res) => {
        this.disposisiCommand = res.data.items;
      });
    },
    onTouchPersonal() {
      this.dropdownPersonal.isTouched = true;
    },
    onChangePersonal(value) {
      this.dropdownPersonal.value = value;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

